<template>
	<div>
		<div class="row mt-4">
			<div class="col-xs-12 col-lg-7 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('general_information') }}
				</div>
			</div>
			<div
				v-if="positionData !== ''"
				class="col-xs-12 col-lg-5 d-flex">
				<div
					class="h4"
					:class="['xs','sm', 'md'].includes(windowWidth) ? 'mb-2 text-left' : 'my-auto ml-auto text-right'">
					<div class="text-primary">
						<span class="font-weight-bold">{{ translate('parent') }}: </span>
						<span>{{ positionData.parent }} </span>
						<span>({{ translate('leg') }}: {{ translate(positionData.leg) }})</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<form
						@submit.prevent="prepareForStorage()"
						@keydown="clearValidationError($event.target.name);"
						@change="clearValidationError($event.target.name);">
						<li class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="my-2">
										<span class="h4">{{ translate('sponsor') }}</span>
										<span
											v-b-tooltip.hover.html.right
											:title="translate('sponsor_description', {company: companyName, supportLink: companyEmail, support: companyEmail})"
											class="text-muted small mx-2">
											<i class="fas fa-lg fa-info-circle" />
										</span>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<div class="form-group">
										<input-text
											id="sponsor"
											:label="translate('sponsor_information')"
											:setter-value="sponsor.id"
											:errors="validationErrors['sponsor']"
											:required="true"
											:disabled="disableSponsor"
											type="text"
											@dataChanged="sponsor.id = $event" />
									</div>
								</div>
							</div>
						</li>
						<li class="list-group-item pb-0 border-bottom-0">
							<div class="row">
								<div class="col-12">
									<div
										class="h4 my-2">
										{{ translate('personal_information') }}
									</div>
									<div
										class="my-2">
										<span>{{ translate('are_you_a_company') }}: </span>
										<switch-toogle
											v-model="isCompany"
											variant="success"
											pill
											class="ml-3" />
									</div>
								</div>
							</div>
						</li>
						<li class="list-group-item">
							<div class="row m-3">
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.first_name`"
											:label="translate('first_name')"
											:setter-value="form.first_name"
											:errors="validationErrors[`${steps[$route.name].validation}.first_name`]"
											:required="true"
											type="text"
											@dataChanged="form.first_name = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.last_name`"
											:label="translate('last_name')"
											:setter-value="form.last_name"
											:errors="validationErrors[`${steps[$route.name].validation}.last_name`]"
											:required="true"
											type="text"
											@dataChanged="form.last_name = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<label>
										{{ translate('gender') }}
										<span class="text-danger">*</span>
									</label>
									<div class="form-group">
										<div class="row">
											<div class="col">
												<select
													id="gender"
													v-model="form.gender"
													:class="typeof validationErrors[`${steps[$route.name].validation}.gender`] !== 'undefined' ? 'is-invalid' : ''"
													type="text"
													name="gender"
													class="form-control">
													<option
														v-for="genderName in gender"
														:key="genderName"
														:value="genderName">
														{{ translate(genderName) }}
													</option>
												</select>
											</div>
										</div>
										<template v-if="typeof validationErrors[`${steps[$route.name].validation}.gender`] !== 'undefined'">
											<span
												v-for="error in validationErrors[`${steps[$route.name].validation}.gender`]"
												:key="error"
												class="custom-invalid-feedback animated fadeIn text-danger"
												v-text="error" />
										</template>
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.email`"
											:label="translate('email')"
											:setter-value="form.email"
											:errors="validationErrors[`${steps[$route.name].validation}.email`]"
											:required="true"
											:max="maxEmailLength"
											type="text"
											@dataChanged="form.email = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<label>
										{{ translate('birthdate') }}
										<span class="text-danger">*</span>
									</label>
									<div class="form-group">
										<div class="row">
											<div class="col pr-0 pr-lg-3">
												<select
													id="year"
													v-model="form.birthdate.year"
													:class="typeof validationErrors[`${steps[$route.name].validation}.birthdate`] !== 'undefined' ? 'is-invalid' : ''"
													type="text"
													name="year"
													class="form-control">
													<option
														v-for="year in years()"
														:key="year"
														:value="year">
														{{ year }}
													</option>
												</select>
											</div>
											<div class="col px-0 px-lg-3">
												<select
													id="month"
													v-model="form.birthdate.month"
													:class="typeof validationErrors[`${steps[$route.name].validation}.birthdate`] !== 'undefined' ? 'is-invalid' : ''"
													type="text"
													name="month"
													class="form-control">
													<option
														v-for="month in months"
														:key="month.key"
														:value="month.value">
														{{ translate(month.key) }}
													</option>
												</select>
											</div>
											<div class="col pl-0 pl-lg-3">
												<select
													id="day"
													v-model="form.birthdate.day"
													:class="typeof validationErrors[`${steps[$route.name].validation}.birthdate`] !== 'undefined' ? 'is-invalid' : ''"
													type="text"
													name="day"
													class="form-control">
													<option
														v-for="day in 31"
														:key="day"
														:value="day <= 9 ? `0${day}` : day">
														{{ day | withCero }}
													</option>
												</select>
											</div>
										</div>
										<template v-if="typeof validationErrors[`${steps[$route.name].validation}.birthdate`] !== 'undefined'">
											<span
												v-for="error in validationErrors[`${steps[$route.name].validation}.birthdate`]"
												:key="error"
												class="custom-invalid-feedback animated fadeIn text-danger"
												v-text="error" />
										</template>
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<label for="mobile_number">
											{{ translate('mobile_number') }}
											<span class="text-danger">*</span>
										</label>
										<vue-tel-input
											:id="`${steps[$route.name].validation}.mobile_number`"
											v-model="form.mobile_number"
											:required="true"
											:label="translate('mobile_number')"
											:placeholder="translate('mobile_number')"
											:class="validationErrors[`${steps[$route.name].validation}.mobile_number`] ? 'is-invalid' : ''"
											v-bind="bindProps"
											type="text"
											:name="`${steps[$route.name].validation}.mobile_number`"
											class="form-control rounded-1"
											@dataChanged="form.mobile_number = $event" />
										<template v-if="validationErrors[`${steps[$route.name].validation}.mobile_number`]">
											<span
												v-for="error in validationErrors[`${steps[$route.name].validation}.mobile_number`]"
												:key="error"
												class="invalid-feedback animated fadeIn"
												v-text="error" />
										</template>
									</div>
								</div>
								<div
									v-if="!isCompany && isNINRequired()"
									class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.national_identification_number`"
											:label="translate(`${userCountry}`)"
											:setter-value="form.national_identification_number"
											:errors="validationErrors[`${steps[$route.name].validation}.national_identification_number`]"
											:required="true"
											type="text"
											@dataChanged="form.national_identification_number = $event" />
									</div>
								</div>
								<div
									v-if="isCompany"
									class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.tax_id`"
											:label="translate('tax_id')"
											:setter-value="form.tax_id"
											:errors="validationErrors[`${steps[$route.name].validation}.tax_id`]"
											:required="true"
											type="text"
											@dataChanged="form.tax_id = $event" />
									</div>
								</div>
								<div
									v-if="isCompany"
									class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.company`"
											:label="translate('company')"
											:setter-value="form.company"
											:errors="validationErrors[`${steps[$route.name].validation}.company`]"
											:required="true"
											type="text"
											@dataChanged="form.company = $event" />
									</div>
								</div>
							</div>
						</li>
						<li class="list-group-item">
							<div class="row">
								<div class="col">
									<div class="h4 my-2">
										{{ translate('account_info') }}
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.username`"
											:label="translate('username')"
											:setter-value="form.username"
											:errors="validationErrors[`${steps[$route.name].validation}.username`]"
											:required="true"
											type="text"
											@dataChanged="form.username = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.password`"
											:label="translate('password')"
											:setter-value="form.password"
											:errors="validationErrors[`${steps[$route.name].validation}.password`]"
											:required="true"
											type="password"
											@dataChanged="form.password = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.password_confirmation`"
											:label="translate('password_confirmation')"
											:setter-value="form.password_confirmation"
											:errors="validationErrors[`${steps[$route.name].validation}.password_confirmation`]"
											:required="true"
											type="password"
											class="text-nowrap"
											@dataChanged="form.password_confirmation = $event" />
									</div>
								</div>
							</div>
						</li>
					</form>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4 ml-auto my-4 text-right">
				<div
					class="btn-group w-100"
					role="group">
					<b-button
						:disabled="!!preparing"
						variant="light"
						size="lg"
						@click="nextStep(true)">
						{{ translate('previous') }}
					</b-button>
					<b-button
						:disabled="validating || !!preparing"
						variant="primary"
						size="lg"
						@click="prepareForStorage()">
						<i
							v-if="validating"
							class="fas fa-spinner fa-spin mr-2" />{{ translate('continue') }}
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import SwitchToogle from '@/components/Switch';
import InputText from '@/components/InputText';
import PaymentProcessor from '@/util/PaymentProcessor';
import { TIMEZONE } from '@/settings/General';
import { GENDERS as gender, REQUIRED_NIN_COUNTRIES } from '@/settings/Profile';
import { MONTHS as months } from '@/settings/Dates';
import { MAX_EMAIL_LENGTH } from '@/settings/Validations';
import { BLACKLIST_UNSET_FIELDS } from '@/settings/Purchase';
import WindowSizes from '@/mixins/WindowSizes';
import CommonMix from './mixins/Common';

export default {
	name: 'RegisterOldPersonalInformation',
	components: { SwitchToogle, InputText, VueTelInput },
	filters: {
		withCero: (value) => ((value <= 9) ? `0${value}` : value),
	},
	mixins: [CommonMix, WindowSizes],
	data() {
		return {
			alert: new this.$Alert(),
			paymentProcessor: new PaymentProcessor(),
			isCompany: false,
			sponsor: {
				id: '',
				name: '',
			},
			hasSponsor: '',
			disableSponsor: false,
			identityId: null,
			gender,
			months,
			maxEmailLength: MAX_EMAIL_LENGTH,
			blackListFields: BLACKLIST_UNSET_FIELDS,
			bindProps: {
				inputOptions: {
					showDialCode: true,
				},
				disabledFetchingCountry: true,
			},
			form: {
				first_name: '',
				last_name: '',
				email: '',
				mobile_number: '',
				gender: gender[0],
				company: '',
				tax_id: '',
				username: '',
				password: '',
				password_confirmation: '',
				birthdate: {
					day: '01',
					month: '01',
					year: '1900',
				},
				national_identification_number: '',
			},
			product: '',
			timezone: TIMEZONE,
			requiredNinCountries: REQUIRED_NIN_COUNTRIES,
			companyName: process.env.VUE_APP_TITLE,
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
		};
	},
	computed: {
		userCountry() {
			if (this.country) {
				return `nin_${this.country.toLowerCase()}`;
			}
			return 'national_identification_number';
		},
	},
	watch: {
		isCompany(value) {
			if (value === false) {
				this.form.national_identification_number = this.form.tax_id;
				this.form.tax_id = '';
				this.form.company = '';
			}
			if (value === true) {
				this.form.tax_id = this.form.national_identification_number;
				this.form.national_identification_number = '';
			}
		},
	},
	created() {
		this.bindProps.defaultCountry = this.country;
		this.bindProps.onlyCountries = [this.country];
		this.getGeneralInformation();
		this.getStoredInformation();
		if (this.$user.auth() === true) {
			this.sponsor.id = String(this.$user.details().id);
			this.disableSponsor = true;
		} else {
			const sponsorId = this.$replicated.replicatedSponsor();
			if (sponsorId !== null) {
				this.sponsor.id = String(sponsorId);
				this.disableSponsor = true;
			}
		}
	},
	methods: {
		years() {
			const startingYear = 1900;
			const currentYear = this.$moment().year();
			const years = [];
			/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
			for (let index = startingYear; index <= currentYear; index++) {
				years.push(index);
			}
			return years;
		},
		getGeneralInformation() {
			const [step] = Object.keys(this.steps).filter((item) => this.steps[item].validation === 'product');
			const previous = this.getStepInformation(step);
			if (typeof previous.product === 'undefined' || Object.keys(previous.product).length < 1) {
				this.nextStep(true);
				return null;
			}
			this.product = { ...previous.product };
			return null;
		},
		getStoredInformation() {
			const {
				sponsor, identity_id: identityId,
			} = this.getStepInformation();
			const dataTemp = { ...this.getStepInformation().personal_information };
			if (!Object.keys(dataTemp).length) {
				return null;
			}
			this.sponsor.id = sponsor;
			this.hasSponsor = true;
			this.identityId = identityId;
			const [year, month, day] = dataTemp.birthdate.split('-');
			dataTemp.birthdate = { year, month, day };
			Object.keys(this.form).forEach((key) => {
				if (typeof dataTemp[key] !== 'undefined' && !this.blackListFields.includes(key)) {
					this.form[key] = dataTemp[key];
				}
			});

			// Remove invalid fields for the current country
			const data = this.clearInvalidFields(dataTemp);

			if (typeof data.is_company === 'boolean') {
				this.isCompany = data.is_company;
			}

			return null;
		},
		prepareForStorage() {
			this.preparing = true;
			// Remove invalid fields for the current country
			const form = this.clearInvalidFields({ ...this.form });
			const { year, month, day } = form.birthdate;
			form.birthdate = `${year}-${month}-${day}`;
			form.is_company = this.isCompany;
			let payload = {
				step: this.steps[this.$route.name].validation, sponsor: this.sponsor.id, personal_information: form, product: this.product, identity_id: this.identityId,
			};

			this.validateStep(payload).then(() => {
				// Save Identity ID
				const identityData = {
					first_name: this.form.first_name,
					last_name: this.form.last_name,
					email: this.form.email,
					mobile_number: this.form.mobile_number,
					identity_id: this.identityId,
				};
				this.paymentProcessor.createIdentity(identityData).then((response) => {
					this.identityId = response.response.identity_id;
					payload = { ...payload, identity_id: this.identityId };
					this.saveStep(payload);
				}).catch(() => {
					this.alert.toast('error', this.translate('identity_error'), { timer: 6000 });
				});
			}).catch(() => {}).finally(() => { this.preparing = false; });
		},
		isNINRequired() {
			return this.requiredNinCountries.includes(this.country);
		},
		clearInvalidFields(data) {
			if (!this.isNINRequired()) {
				data.national_identification_number = '';
			}

			return data;
		},
	},
};
</script>
